import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

interface Props {
  direction: 'row' | 'column' | undefined
}

export const GridContainer = styled(Grid)<{ container?: boolean }>`
  box-shadow: ${({ container }) =>
    container ? '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)' : 'none'};
  padding: 2rem ${({ container }) => (container ? '2rem' : '0')};
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`

export const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: ${(props: Props) => props?.direction ?? 'row'};
  gap: 0.5rem;
  justify-content: center;
  align-items: flex-start;
`
