import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { UpdateCampaign } from 'models/campaigns'
import { updateOneCampaign } from 'services/campaigns'
import { GET_CAMPAIGNS, GET_GROUPS } from 'config/queries'

const useUpdateCampaign = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const updateCampaign = useMutation({
    mutationFn: ({ id, ...payload }: UpdateCampaign) => updateOneCampaign(id, { id, ...payload }),
    onSuccess: () => {
      toast(t('Campaña actualizada'), { type: 'success' })
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [GET_CAMPAIGNS] })
      const previous = queryClient.getQueryData<{ data: UpdateCampaign[] }>([GET_CAMPAIGNS])

      return { previous }
    },
    onError: (error, newValue, context) => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.setQueryData([GET_CAMPAIGNS], context?.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
    },
  })

  return updateCampaign
}

export default useUpdateCampaign
