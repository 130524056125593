import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

import { colors } from 'config/theme'

const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 2.5rem;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10;
  padding: 1.5rem 0;
  // border-top: 1px solid #e0e0e0;
  // box-shadow: ${colors.deepBlue + '44'} 0px -7px 8px;
` as typeof Grid

export default Container
