import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { GET_CAMPAIGNS, GET_GROUPS, GET_ONE_CAMPAIGN_DRAFT } from 'config/queries'
import { publishDraft } from 'services/campaigns'

const usePublishDraft = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const publishDraftMutation = useMutation({
    mutationFn: (id: string) => {
      return publishDraft(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
      queryClient.invalidateQueries({ queryKey: [GET_ONE_CAMPAIGN_DRAFT] })
      toast.success(t('Campaña publicada'))
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
    },
  })

  return publishDraftMutation
}

export default usePublishDraft
