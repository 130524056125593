import React from 'react'

import { Box, Typography } from '@mui/material'
import { t } from 'i18next'

import { FontSizes } from 'config/constants'

interface Props {
  from: string
  to: string
}

const PeriodDateCampaignCell: React.FC<Props> = props => {
  return (
    <Box width='100%' display='flex' flexDirection='column'>
      <Typography noWrap fontSize={FontSizes.base}>
        <strong>{t('Desde')}:</strong> {props.from.slice(0, 10)}
      </Typography>
      <Typography noWrap fontSize={FontSizes.base}>
        <strong>{t('Hasta')}:</strong> {props.to.slice(0, 10)}
      </Typography>
    </Box>
  )
}

export default PeriodDateCampaignCell
