import { useQuery } from 'react-query'

import { GET_ONE_CAMPAIGNS_VERSIONS } from 'config/queries'
import { getOneCampaignsVersions } from 'services/campaigns'
import { Campaign, UseGetOneCampaignVersion } from 'models/campaigns'

interface Args {
  id?: string
  idManaged?: string
}

// ToDo: add timestamp
const useGetOneCampaignVersion = ({ id, idManaged }: Args): UseGetOneCampaignVersion => {
  const queryKey = [GET_ONE_CAMPAIGNS_VERSIONS, id, idManaged]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneCampaignsVersions({ id, idManaged }),
    refetchOnMount: true,
  })

  const result: Campaign = response?.data[0] || []

  return { response: result, isLoading }
}

export default useGetOneCampaignVersion
