import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import useGetOneCampaign from 'hooks/queries/campaign/useGetOneCampaign'
import CampaignsEditForm from 'components/organisms/campaigns/campaigns-edit-form'

const CampaignsShowPage = (): JSX.Element => {
  const { id } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isLoading, response } = useGetOneCampaign({ id: id! })
  return (
    <PageContainer>
      <CampaignsEditForm isLoading={isLoading} response={response} campaignId={id} disabled />
    </PageContainer>
  )
}

export default CampaignsShowPage
