/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import SpinnerLoading from 'components/atoms/spinner-loading'
import { Campaign, UpdateCampaign } from 'models/campaigns'
import { CampaignFormFields, campaignSchema } from 'validations/campaigns'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import useGetGroups from 'hooks/queries/group/useGetGroups'
import CustomTabPanel from 'components/atoms/custom-tab-panel'
import ControllerSwitch from 'components/molecules/controller-switch'
import CampaignHistoric from 'components/pages/campaigns/campaigns-historic'
import ControllerInputImage from 'components/molecules/controller-input-image'
import CancelButton from 'components/atoms/cancel-button'
import SubmitButton from 'components/atoms/submit-button'
import FormButtonsContainerSticky from 'components/atoms/form-button-container-sticky'
import { IMAGES_SIZES, TABS_LANGUAGES } from 'config/constants'
import useUpdateCampaign from 'hooks/queries/campaign/useUpdateCampaign'
import routes from 'config/routes'

import { GridContainer, GridItem, StyledContainer } from './styled'

interface Props {
  response: Campaign
  isLoading: boolean
  campaignId?: string
  disabled?: boolean
  historic?: boolean
}

const defaultValues: CampaignFormFields = {
  id: '',
  id_managed: '',
  priority: 0,
  name: '',
  domain: [],
  startTimestamp: '',
  endTimestamp: '',
  type: '',
  activationMode: false,
  countries: [],
}

const CampaignsEditForm: React.FC<Props> = ({ isLoading, response, disabled, campaignId }) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)
  const [tabLanguage, setTabLanguage] = useState(0)
  const updateCampaign = useUpdateCampaign()
  const navigate = useNavigate()

  const { response: groupsResponse } = useGetGroups({
    offset: 0,
    limit: 1000,
    filters: { keyType: 'GROUP' },
    sort: 'id',
  })

  const groups = useMemo(() => {
    return [...groupsResponse.map(group => ({ value: group.name, text: group.name }))]
  }, [groupsResponse])

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleLanguageChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabLanguage(newValue)
  }

  /*  const { response: domainsResponse } = useGetDomains()

  const domains = useMemo(
    () => domainsResponse?.map?.(({ name: domain }: Domains) => ({ value: domain, text: t(domain) })),
    [domainsResponse, t]
  ) */

  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    watch,
  } = useForm<CampaignFormFields>({
    defaultValues,
    resolver: yupResolver(campaignSchema),
  })
  const onSubmit = useCallback(
    async (payload: CampaignFormFields) => {
      const body: UpdateCampaign = {
        id: campaignId ?? '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        idManaged: (response as any).id_managed,
        name: payload.name,
        priority: payload.priority,
        type: payload.type,
        activation_mode: payload.activationMode ? 'AUTOMATIC' : 'MANUAL',
        default_language: 'es',
        start_timestamp: payload.startTimestamp,
        end_timestamp: payload.endTimestamp,
        countries: payload.countries,
        conditions: payload.domain ? [{ kind_of: 'GROUP_KEY', value: payload.domain }] : undefined,
      }
      await updateCampaign.mutateAsync(body)
      navigate(routes.campaignsList)
    },
    [campaignId, navigate, response, updateCampaign]
  )

  useEffect(() => {
    if (!response) return

    reset({
      id: response.id,
      // domain: response.domain?.name || '',
      name: response.name || '',
      type: response.type || '',
      startTimestamp: response.start_timestamp,
      endTimestamp: response.end_timestamp,
      activationMode: response.activation_mode === 'AUTOMATIC',
      priority: response.priority,
      countries: response.countries || [],
      domain: response.conditions?.[0]?.value || [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      id_managed: (response as any).id_managed,
    })
  }, [response, reset, groups])

  if (isLoading) {
    return <SpinnerLoading fullHeight />
  }

  const selectedType = watch('type')

  return (
    <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='campaigns-edit-form'>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem' }}>
        <Tabs value={tabValue} onChange={handleChangeTab} aria-label='campaigns form tabs'>
          <Tab label={t('Información básica')} />
          <Tab label={t('Descuentos y parkings')} disabled={selectedType === 'INFO'} />
          <Tab label={t('Histórico')} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <GridContainer container>
          <GridContainer gap={1}>
            <GridItem item md={2} xs={5}>
              <ControllerInputText
                mandatory
                control={control}
                name='priority'
                error={errors.priority}
                label={t('Nº Prioridad')}
                size='full'
                disabled={disabled}
              />
            </GridItem>
            <GridItem item md={9} xs={6}>
              <ControllerInputText
                mandatory
                control={control}
                name='name'
                error={errors.name}
                label={t('Nombre campaña')}
                size='full'
                disabled={disabled}
              />
            </GridItem>
            <GridItem item md={7} xs={12}>
              <ControllerInputText
                mandatory
                control={control}
                name='id_managed'
                error={errors.id_managed}
                label={t('Id Managed')}
                size='full'
                disabled
              />
            </GridItem>
            <GridItem item md={4} xs={12}>
              <Controller
                name='type'
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field} aria-labelledby='radio-buttons-type' name='type'>
                    <FormControlLabel value='INFO' control={<Radio />} label={t('Información')} disabled />
                    <FormControlLabel value='VOUCHER' control={<Radio />} label={t('Descuento')} disabled />
                  </RadioGroup>
                )}
              />
            </GridItem>
            <GridItem item md={7} xs={12}>
              <ControllerInputSelect
                mandatory
                control={control}
                name='domain'
                error={errors.domain?.[0]}
                label={t('Grupos')}
                size='full'
                options={groups}
                disabled
                multiple
              />
            </GridItem>
            <GridItem item md={4} xs={12}>
              <ControllerSwitch
                label={t('Activación automática')}
                name='activationMode'
                variant='full'
                control={control}
                autoHeight
                disabled
              />
            </GridItem>
            <GridItem md={11} item xs={12}>
              <StyledContainer direction='row'>
                <ControllerInputDateTimePicker
                  mandatory
                  label={t('Desde')}
                  name='startTimestamp'
                  control={control}
                  error={errors.startTimestamp}
                  size='full'
                  disabled={disabled || new Date() > new Date(watch('endTimestamp'))}
                />
                <ControllerInputDateTimePicker
                  mandatory
                  label={t('Hasta')}
                  name='endTimestamp'
                  control={control}
                  error={errors.endTimestamp}
                  size='full'
                  disabled={disabled || new Date() > new Date(watch('endTimestamp'))}
                />
              </StyledContainer>
            </GridItem>
          </GridContainer>
          <GridItem md={3} item xs={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Tabs
                value={tabLanguage}
                onChange={handleLanguageChange}
                orientation='vertical'
                aria-label='campaigns languages tabs'
              >
                {TABS_LANGUAGES.map((language, index) => (
                  <Tab key={index} label={t(language.label)} />
                ))}
              </Tabs>
            </Box>
          </GridItem>
          <GridItem md={9} item xs={8}>
            {TABS_LANGUAGES.map((language, index) => (
              <CustomTabPanel key={index} value={tabLanguage} index={index}>
                <ControllerInputText
                  control={control}
                  name={`countries.[0].configurations.${language.value}.title`}
                  label={t('Título')}
                  size='full'
                  disabled
                />
                <ControllerInputText
                  control={control}
                  name={`countries.[0].configurations.${language.value}.details`}
                  label={t('Descripción')}
                  size='full'
                  inputProps={{
                    multiline: true,
                    rows: 4,
                  }}
                  disabled
                />
                <ControllerInputText
                  control={control}
                  name={`countries.[0].configurations.${language.value}.cta`}
                  label={t('CTA de activación')}
                  size='full'
                  disabled
                />
                <ControllerInputText
                  control={control}
                  name={`countries.[0].configurations.${language.value}.info_page_url`}
                  label={t('Enlace informativo')}
                  size='full'
                  disabled
                />
                <ControllerInputText
                  control={control}
                  name={`countries.[0].configurations.${language.value}.link_name`}
                  label={t('Nombre del enlace')}
                  size='full'
                  disabled
                />
                <Typography variant='h6' sx={{ mt: 2 }}>
                  {t('Imágenes')}
                </Typography>
                {IMAGES_SIZES.map((image, i) => (
                  <ControllerInputImage
                    mandatory
                    key={i}
                    control={control}
                    name={`countries.[0].configurations.${language.value}.${image.value}`}
                    label={image.label}
                    size='full'
                    disabled
                  />
                ))}
              </CustomTabPanel>
            ))}
          </GridItem>
        </GridContainer>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <GridContainer container>Descuentos de campaña</GridContainer>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <GridContainer container>
          <GridItem md={12} item xs={12}>
            <CampaignHistoric />
          </GridItem>
        </GridContainer>
      </CustomTabPanel>
      <FormButtonsContainerSticky>
        <CancelButton />
        {!disabled && <SubmitButton disabled={!isDirty} />}
      </FormButtonsContainerSticky>
    </Grid>
  )
}

export default CampaignsEditForm
