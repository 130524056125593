import { useCallback, useState } from 'react'

import { Box } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Campaign, CampaignDraft } from 'models/campaigns'
import routes from 'config/routes'
import ModalConfirm from 'components/molecules/modal-confirm'
import useCloneCampaign from 'hooks/queries/campaign/useCloneCampaign'

import BasicTooltip from '../basic-tooltip'

import { StyledLink } from './styled'

interface Args {
  campaign: Campaign | CampaignDraft
}

// eslint-disable-next-line react/prop-types
const CampaignsActions: React.FC<Args> = ({ campaign }) => {
  const isCampaignDraft = (item: Campaign | CampaignDraft): boolean => 'campaignId' in item
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showModalClone, setShowModalClone] = useState(false)

  const navigateToEditCampaign = useCallback(
    (id: string) => navigate(routes.campaignsEdit.replace(':id', id)),
    [navigate]
  )
  const navigateToEditDraft = useCallback(
    (id: string) => navigate(routes.campaignDraftEdit.replace(':id', id)),
    [navigate]
  )

  const isDraftAvailable = !isCampaignDraft(campaign) && (campaign as unknown as Campaign).drafts === true

  const { mutate } = useCloneCampaign({
    onSuccess: data => {
      setShowModalClone(false)
      navigateToEditDraft(data?.id)
    },
    onError: () => {
      setShowModalClone(false)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
  })

  const handleClone = (id: string) => {
    mutate({ id })
  }

  return (
    <>
      <BasicTooltip
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0.5rem',
              padding: '0.5rem 1.125rem',
            }}
          >
            {!isCampaignDraft(campaign) && <StyledLink>{t('Desactivar')}</StyledLink>}
            {!isCampaignDraft(campaign) && !isDraftAvailable && (
              <>
                <StyledLink onClick={() => navigateToEditCampaign((campaign as unknown as Campaign).id)}>
                  {t('Editar campaña')}
                </StyledLink>
                <StyledLink onClick={() => setShowModalClone(true)}>{t('Clonar')}</StyledLink>
              </>
            )}
            {isDraftAvailable && (
              <>
                <StyledLink onClick={() => navigateToEditCampaign((campaign as unknown as Campaign).id)}>
                  {t('Editar publicada')}
                </StyledLink>
                <StyledLink
                  onClick={() => navigateToEditDraft((campaign as unknown as Campaign).draftId?.toString() ?? '')}
                >
                  {t('Editar borrador')}
                </StyledLink>
              </>
            )}
            {isCampaignDraft(campaign) && (
              <>
                <StyledLink onClick={() => navigateToEditDraft((campaign as unknown as CampaignDraft).id)}>
                  {t('Editar borrador')}
                </StyledLink>
              </>
            )}
          </Box>
        }
      >
        <MoreVertIcon color='primary' />
      </BasicTooltip>
      <ModalConfirm
        open={showModalClone}
        onCancel={() => setShowModalClone(false)}
        onConfirm={() => handleClone((campaign as unknown as Campaign).id)}
        title={t('Clonar campaña')}
        message={t(
          // eslint-disable-next-line max-len
          'Clonar una campaña no significa que esté duplicando la información para crear una nueva. Cuando publique este clon, se desactivará la campaña que está clonando. ¿Quiere continuar?'
        )}
        confirmLabel={t('Clonar')}
        cancelLabel={t('cancelar')}
      />
    </>
  )
}

export default CampaignsActions
