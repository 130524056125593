import React, { useCallback } from 'react'

import { GridEnrichedColDef, GridSelectionModel } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import { Group, GroupsQueryKey } from 'models/groups'
import { RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import { Campaign, CampaignDraft } from 'models/campaigns'
import PeriodDateCampaignCell from 'components/atoms/period-date-campaign-cell'
import ShowGridDraftsInfoCell from 'components/atoms/show-grid-drafts-info-cell-item'
import CampaignsGridActionCell from 'components/atoms/campaigns-grid-action-cell'

interface Props {
  rows: (Campaign | CampaignDraft)[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
  queryKey?: GroupsQueryKey
  type?: TableTypes
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  selectionModel?: GridSelectionModel
}

const CampaignsTable: React.FC<Props> = ({ queryKey, type, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isCampaignDraft = (item: Campaign | CampaignDraft): boolean => 'campaignId' in item

  const navigateToShowCampaign = useCallback(
    (id: string) => !type && navigate(routes.campaignsShow.replace(':id', id)),
    [navigate, type]
  )

  const navigateToShowCampaignDraft = useCallback(
    (id: string) => !type && navigate(routes.campaignDraftShow.replace(':id', id)),
    [navigate, type]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Campaign }) => {
      const actions = [
        <ShowGridActionCellItem
          key={`${row.id}-show`}
          onClick={() => (isCampaignDraft(row) ? navigateToShowCampaignDraft(row.id) : navigateToShowCampaign(row.id))}
        />,
        <CampaignsGridActionCell key={`${row.id}-event`} campaign={row} />,
      ]

      return actions
    },
    [navigateToShowCampaign, navigateToShowCampaignDraft]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: '',
      flex: TableColumnWidth.small,
      renderCell: ({ row }: { row: Campaign }) => (
        <ShowGridDraftsInfoCell
          key={`${row.draftId}`}
          hasDrafts={row.drafts || isCampaignDraft(row)}
          isPublished={!isCampaignDraft(row)}
          publishedID={row.id}
          draftID={isCampaignDraft(row) ? row.id : row.draftId?.toString()}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'id managed',
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Campaign }) => (
        <TextCell
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          text={isCampaignDraft(row) ? (row as unknown as CampaignDraft).idManaged : (row as any).id_managed}
        />
      ),
    },
    {
      field: 'priority',
      headerName: 'Prioridad',
      flex: TableColumnWidth.small,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'country',
      headerName: `${t('País')}`,
      flex: TableColumnWidth.xs,
      renderCell: ({ row }: { row: Campaign }) => (
        <TextCell
          text={isCampaignDraft(row) ? (row as unknown as CampaignDraft).language : (row as Campaign).default_language}
        />
      ),
    },
    {
      field: 'name',
      headerName: `${t('Campaña')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Campaign }) => <TextCell text={row.name} />,
    },
    {
      field: 'type',
      headerName: `${t('Tipo')}`,
      flex: TableColumnWidth.small,
      renderCell: ({ row }: { row: Campaign }) => <TextCell text={row.type} />,
    },
    {
      field: 'date',
      headerName: `${t('Validez')}`,
      flex: TableColumnWidth.large,
      sortable: false,
      renderCell: ({ row }: { row: Campaign | CampaignDraft }) => (
        <PeriodDateCampaignCell
          from={isCampaignDraft(row) ? (row as CampaignDraft).from ?? '' : (row as Campaign).start_timestamp ?? ''}
          to={isCampaignDraft(row) ? (row as CampaignDraft).to ?? '' : (row as Campaign).end_timestamp ?? ''}
        />
      ),
    },
    {
      field: 'activationMode',
      headerName: `${t('Activación')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Campaign }) => (
        <TextCell
          text={
            isCampaignDraft(row) ? (row as unknown as CampaignDraft).activationMode : (row as Campaign).activation_mode
          }
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.small,
      getActions: getTableActions,
    },
  ]

  const selectionActions: GridEnrichedColDef = {
    field: 'actions',
    type: 'actions',
    flex: TableColumnWidth.small,
    getActions: ({ row }: { row: Group }) => [
      <ToggleGridActionCellItem checked={row.active} key={`${row.id}-toggle`} onClick={() => {}} />,
    ],
  }

  const selectionColums: GridEnrichedColDef[] = columns.map((column: GridEnrichedColDef) =>
    column.field !== 'actions' ? column : selectionActions
  )

  return (
    <BasicTable
      {...props}
      rowHeight={RowHeight.large}
      type={type}
      columns={type ? selectionColums : columns}
      onRowClick={() => null}
      rowsPerPageOptionsShow={false}
    />
  )
}

export default CampaignsTable
