import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { CreateCampaign } from 'models/campaigns'
import { createCampaign } from 'services/campaigns'
import { GET_CAMPAIGNS, GET_GROUPS } from 'config/queries'

const useCreateCampaign = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createCampaignMutation = useMutation({
    mutationFn: (payload: CreateCampaign) => createCampaign(payload),
    onSuccess: () => {
      toast(t('Campaña creada'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
    },
  })

  return createCampaignMutation
}

export default useCreateCampaign
