import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import useGetOneCampaignVersion from 'hooks/queries/campaign/useGetOneCampaignsVersions'
import CampaignsEditForm from 'components/organisms/campaigns/campaigns-edit-form'

const CampaignsShow = (): JSX.Element => {
  const { id, idManaged } = useParams()
  const { isLoading, response } = useGetOneCampaignVersion({ id, idManaged })
  return (
    <PageContainer>
      <CampaignsEditForm isLoading={isLoading} response={response} campaignId={id} disabled historic />
    </PageContainer>
  )
}

export default CampaignsShow
