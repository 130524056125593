import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { deleteOneDraft } from 'services/campaigns'
import { GET_CAMPAIGNS, GET_GROUPS, GET_ONE_CAMPAIGN_DRAFT } from 'config/queries'

const useDeleteOneDraft = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const deleteDraftMutation = useMutation({
    mutationFn: (id: string) => {
      return deleteOneDraft(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CAMPAIGNS])
      queryClient.invalidateQueries([GET_ONE_CAMPAIGN_DRAFT])
      queryClient.invalidateQueries([GET_GROUPS])
      toast.success(t('Borrador eliminado'))
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
    },
  })

  return { deleteDraftMutation }
}

export default useDeleteOneDraft
