import { GridActionsCellItem } from '@mui/x-data-grid'

import { Campaign, CampaignDraft } from 'models/campaigns'

import CampaignsActions from '../campaigns-actions'

interface Args {
  campaign: Campaign | CampaignDraft
}
// eslint-disable-next-line react/prop-types
const CampaignsGridActionCell: React.FC<Args> = ({ campaign }) => (
  <GridActionsCellItem icon={<CampaignsActions campaign={campaign} />} label='actions' />
)

export default CampaignsGridActionCell
