import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { CampaignDraft } from 'models/campaigns'
import { cloneCampaign } from 'services/campaigns'
import { GET_CAMPAIGNS } from 'config/queries'

interface Args {
  id: string
}

const useCloneCampaign = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: CampaignDraft) => void
  onError?: (error: unknown) => void
} = {}): {
  mutate: (args: Args) => void
  response: CampaignDraft | null
  isLoading: boolean
} => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const {
    mutate,
    data: response,
    isLoading,
  } = useMutation({
    mutationFn: ({ id }: Args) => cloneCampaign(id),
    onSuccess: data => {
      if (onSuccess) onSuccess(data.data)
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
      toast.success(t('Campaña clonada'))
    },
    onError: error => {
      if (onError) onError(error)
      toast.error(t('Ha ocurrido un error'))
    },
  })

  const result: CampaignDraft | null = response?.data || null

  return { mutate, response: result, isLoading }
}

export default useCloneCampaign
