import { InputProps, TextField, Typography } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import UploadIcon from '@mui/icons-material/Upload'
import { t } from 'i18next'
import DeleteIcon from '@mui/icons-material/Delete'

import { ControllerField } from 'models/form'
import { Sizes } from 'models/sizes'
import { inputsWidth } from 'config/constants'

import { StyledBox } from './styled'

interface Props {
  field?: ControllerField
  label: string
  mandatory?: boolean
  defaultRequired?: boolean
  helperText?: string
  error?: boolean
  size: Sizes
  disabled?: boolean
  inputProps?: InputProps
}

const BasicInputCSV: React.FC<Props> = ({
  field,
  label,
  mandatory,
  defaultRequired,
  helperText,
  error,
  size,
  disabled,
  inputProps,
}: Props) => {
  return (
    <StyledBox width={inputsWidth[size]} disabled={disabled}>
      <Typography className='header'>{mandatory ? `${label} *` : label}</Typography>
      {!(field?.value as string) && !disabled && (
        <TextField
          {...field}
          required={defaultRequired}
          helperText={helperText}
          error={error}
          InputProps={inputProps}
          disabled={disabled}
          fullWidth
          type='file'
          inputProps={{ accept: 'text/csv' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => field?.onChange(e.target.files?.[0])}
        />
      )}

      {!(field?.value as string) && (
        <div className='csv-loader-container'>
          <UploadIcon />
          <Typography
            className='csv-loader-action'
            dangerouslySetInnerHTML={{ __html: t('Clica para adjuntar o arrastra hasta esta zona') }}
          />
          <Typography className='csv-loader-type'>{t('CSV')}</Typography>
        </div>
      )}
      {(field?.value as string) && !disabled && (
        <>
          <div className='csv-loaded-container'>
            <Typography className='csv-loaded-path'>
              <DescriptionIcon /> {typeof field?.value === 'string' ? field?.value : (field?.value as File).name}
            </Typography>
            {!disabled && (
              <a className='csv-loaded-delete' onClick={() => field?.onChange(null)}>
                <DeleteIcon />
              </a>
            )}
          </div>
          <Typography className='csv-loaded-info'>
            {t('Podrás visualizar los descuentos luego de que publiques la campaña.')}
          </Typography>
        </>
      )}
    </StyledBox>
  )
}

export default BasicInputCSV
