import { useQuery } from 'react-query'

import { GET_ONE_CAMPAIGN_DRAFT } from 'config/queries'
import { CampaignDraft, UseGetOneCampaignDraft } from 'models/campaigns'
import { getOneCampaignDraft } from 'services/campaigns'

interface Args {
  id: string
}

const useGetOneCampaignDraft = ({ id }: Args): UseGetOneCampaignDraft => {
  const queryKey = [GET_ONE_CAMPAIGN_DRAFT, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneCampaignDraft(id),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: CampaignDraft = response?.data

  return { response: result, isLoading }
}

export default useGetOneCampaignDraft
