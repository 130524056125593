import styled from '@emotion/styled'

import { colors } from 'config/theme'

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${colors.deepBlue};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
