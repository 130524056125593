import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { uploadFile } from 'services/file'

const useUploadFile = () => {
  const { t } = useTranslation()
  const uploadFileMutation = useMutation({
    mutationFn: (file: Blob) => uploadFile(file),
    onSuccess: () => {},
    onError: () => {
      toast(t('Ha ocurrido un error al subir la imagen'), { type: 'error' })
    },
  })

  return uploadFileMutation
}

export default useUploadFile
