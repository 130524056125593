/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import SpinnerLoading from 'components/atoms/spinner-loading'
import { CampaignDraft, UpdateDraft } from 'models/campaigns'
import { campaignSchema } from 'validations/campaigns'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import useGetGroups from 'hooks/queries/group/useGetGroups'
import CustomTabPanel from 'components/atoms/custom-tab-panel'
import ControllerSwitch from 'components/molecules/controller-switch'
import CampaignHistoric from 'components/pages/campaigns/campaigns-historic'
import ControllerInputImage from 'components/molecules/controller-input-image'
import FormButtonsContainerSticky from 'components/atoms/form-button-container-sticky'
import { IMAGES_SIZES, TABS_LANGUAGES } from 'config/constants'
import BasicButton from 'components/atoms/basic-button'
import ModalConfirm from 'components/molecules/modal-confirm'
import useUpdateDraft from 'hooks/queries/draft/useUpdateDraft'
import useDeleteOneDraft from 'hooks/queries/draft/useDeleteOneDraft'
import routes from 'config/routes'
import { DraftFormFields, DraftFormSchema } from 'validations/drafts'
import { convertToPng } from 'utils/convertToImagePng'
import useUploadFile from 'hooks/queries/file/useUploadFile'
import { getFile } from 'services/file'
import usePublishDraft from 'hooks/queries/draft/usePublishDraft'
import ControllerInputCSV from 'components/molecules/controller-input-csv'
import useUploadCampaignCsv from 'hooks/queries/campaign/useUploadCampaignCsv'
import CampaignDiscounts from 'components/pages/campaigns/campaigns-discounts'

import { GridContainer, GridItem, StyledContainer } from './styled'

interface Props {
  response: CampaignDraft
  isLoading: boolean
  campaignId?: string
  disabled?: boolean
  historic?: boolean
}

const defaultValues: DraftFormFields = {
  id: '',
  idManaged: '',
  priority: 0,
  name: '',
  domain: [],
  startTimestamp: '',
  endTimestamp: '',
  type: '',
  activationMode: false,
  resources: [],
}

const DraftsEditForm: React.FC<Props> = ({ isLoading, response, disabled, campaignId }) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)
  const [tabLanguage, setTabLanguage] = useState(0)
  const [action, setAction] = useState<'save_draft' | 'publish' | null>(null)
  const [showBackModal, setShowBackModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const updateDraft = useUpdateDraft()
  const deleteDraft = useDeleteOneDraft()
  const publishDraft = usePublishDraft()
  const { mutate: uploadFile } = useUploadFile()
  const uploadCSV = useUploadCampaignCsv()

  const { response: groupsResponse } = useGetGroups({
    offset: 0,
    limit: 1000,
    filters: { keyType: 'GROUP' },
    sort: 'id',
  })

  const groups = useMemo(() => {
    return [...groupsResponse.map(group => ({ value: group.name, text: group.name }))]
  }, [groupsResponse])

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleLanguageChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabLanguage(newValue)
  }

  /* const { response: domainsResponse } = useGetDomains()

  const domains = useMemo(
    () => domainsResponse?.map?.(({ name: domain }: Domains) => ({ value: domain, text: t(domain) })),
    [domainsResponse, t]
  ) */

  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    watch,
  } = useForm<DraftFormSchema>({
    defaultValues,
    resolver: yupResolver(campaignSchema),
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = useCallback(
    async (payload: DraftFormSchema) => {
      const body: UpdateDraft = {
        id: campaignId ?? '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        idManaged: payload.idManaged,
        name: payload.name,
        priority: payload.priority,
        type: payload.type,
        activation_mode: payload.activationMode ? 'AUTOMATIC' : 'MANUAL',
        default_language: 'es',
        start_timestamp: payload.startTimestamp,
        end_timestamp: payload.endTimestamp,
        resources: payload.resources,
        conditions: payload.domain ? [{ kind_of: 'GROUP_KEY', value: payload.domain }] : undefined,
      }
      if (action === 'save_draft') {
        /** IMAGES START*/
        if (payload.resources) {
          for (const language of TABS_LANGUAGES) {
            const langCode = language.value

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const payloadResources = (payload?.resources?.[0] as any)?.configurations as { [key: string]: any }

            if (payloadResources[langCode]) {
              for (const imageSize of IMAGES_SIZES) {
                const sizeCode = imageSize.value
                const resource = payloadResources[langCode][sizeCode]

                if (resource !== null && resource !== undefined && typeof resource !== 'string') {
                  try {
                    const image = await convertToPng(resource)

                    await new Promise<void>((resolve, reject) => {
                      uploadFile(image, {
                        onSuccess: async result => {
                          try {
                            const file = await getFile(result.data.id)
                            body.resources[0].configurations[langCode][sizeCode] = file.data.url
                            resolve()
                          } catch (error) {
                            reject(error)
                          }
                        },
                        onError: error => reject(error),
                      })
                    })
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error)
                  }
                }
              }
            }
          }
        }
        /** IMAGES END */

        if (payload.type === 'VOUCHER' && payload.csv) {
          uploadCSV.mutate(
            { id: payload.id ?? '', file: payload.csv },
            {
              onSuccess: async resp => {
                // eslint-disable-next-line no-console
                console.log(resp)
              },
            }
          )
        }

        await updateDraft.mutateAsync(body)
        window.location.reload()
        // navigate(routes.campaignsList)
      } else if (action === 'publish') {
        setShowConfirmModal(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, campaignId, navigate, response, updateDraft]
  )

  useEffect(() => {
    if (!response) return

    reset({
      id: response.id,
      // domain: response.domain?.name || '',
      name: response.name || '',
      type: response.type || '',
      startTimestamp: response?.campaignId !== null ? new Date().toISOString() : response.from,
      endTimestamp: response.to,
      activationMode: response.activationMode === 'AUTOMATIC',
      priority: response.priority,
      resources: response.resources || {},
      domain: response.conditions?.[0]?.value || [],
      idManaged: response.idManaged,
    })
  }, [response, reset, groups])

  if (isLoading) {
    return <SpinnerLoading fullHeight />
  }

  const handleBack = () => {
    if (isDirty) {
      setShowBackModal(true)
    } else {
      navigate(routes.campaignsList)
    }
  }

  const handleDelete = async () => {
    deleteDraft.deleteDraftMutation.mutate(campaignId ?? '')
    navigate(routes.campaignsList)
  }

  const publishCampaign = async () => {
    await publishDraft.mutateAsync(campaignId ?? '')
    navigate(routes.campaignsList)
  }

  const selectedType = watch('type')

  return (
    <>
      <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='campaigns-edit-form'>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem' }}>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label='campaigns form tabs'>
            <Tab label={t('Información básica')} />
            <Tab label={t('Descuentos y parkings')} disabled={selectedType === 'INFO'} />
            {disabled && <Tab label={t('Histórico')} />}
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <GridContainer container>
            <GridContainer gap={1}>
              <GridItem item md={2} xs={5}>
                <ControllerInputText
                  mandatory
                  control={control}
                  name='priority'
                  error={errors.priority}
                  label={t('Nº Prioridad')}
                  size='full'
                  disabled={disabled}
                />
              </GridItem>
              <GridItem item md={9} xs={6}>
                <ControllerInputText
                  mandatory
                  control={control}
                  name='name'
                  error={errors.name}
                  label={t('Nombre campaña')}
                  size='full'
                  disabled={disabled}
                />
              </GridItem>
              <GridItem item md={7} xs={12}>
                <ControllerInputText
                  mandatory
                  control={control}
                  name='idManaged'
                  error={errors.idManaged}
                  label={t('Id Managed')}
                  size='full'
                  disabled={disabled || !!response?.campaignId}
                />
              </GridItem>
              <GridItem item md={4} xs={12}>
                <Controller
                  name='type'
                  control={control}
                  render={({ field }) => (
                    <RadioGroup row {...field} aria-labelledby='radio-buttons-type' name='type'>
                      <FormControlLabel value='INFO' control={<Radio />} label={t('Información')} disabled={disabled} />
                      <FormControlLabel
                        value='VOUCHER'
                        control={<Radio />}
                        label={t('Descuento')}
                        disabled={disabled}
                      />
                    </RadioGroup>
                  )}
                />
              </GridItem>
              <GridItem item md={7} xs={12}>
                <ControllerInputSelect
                  mandatory
                  control={control}
                  name='domain'
                  error={errors.domain ? (errors.domain as FieldError) : undefined}
                  label={t('Grupos')}
                  size='full'
                  options={groups}
                  disabled={disabled}
                  multiple
                />
              </GridItem>
              <GridItem item md={4} xs={12}>
                <ControllerSwitch
                  label={t('Activación automática')}
                  name='activationMode'
                  variant='full'
                  control={control}
                  autoHeight
                  disabled={disabled}
                />
              </GridItem>
              <GridItem md={11} item xs={12}>
                <StyledContainer direction='row'>
                  <ControllerInputDateTimePicker
                    mandatory
                    label={t('Desde')}
                    name='startTimestamp'
                    control={control}
                    error={errors.startTimestamp}
                    size='full'
                    disabled={disabled || !!response?.campaignId}
                  />
                  <ControllerInputDateTimePicker
                    mandatory
                    label={t('Hasta')}
                    name='endTimestamp'
                    control={control}
                    error={errors.endTimestamp}
                    size='full'
                    disabled={disabled}
                  />
                </StyledContainer>
              </GridItem>
            </GridContainer>
            <GridItem md={3} item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Tabs
                  value={tabLanguage}
                  onChange={handleLanguageChange}
                  orientation='vertical'
                  aria-label='campaigns languages tabs'
                >
                  {TABS_LANGUAGES.map((language, index) => (
                    <Tab key={index} label={t(language.label)} />
                  ))}
                </Tabs>
              </Box>
            </GridItem>
            <GridItem md={9} item xs={8}>
              {TABS_LANGUAGES.map((language, index) => (
                <CustomTabPanel key={index} value={tabLanguage} index={index}>
                  <ControllerInputText
                    control={control}
                    name={`resources.[0].configurations.${language.value}.title`}
                    label={t('Título')}
                    size='full'
                    disabled={disabled}
                  />
                  <ControllerInputText
                    control={control}
                    name={`resources.[0].configurations.${language.value}.details`}
                    label={t('Descripción')}
                    size='full'
                    inputProps={{
                      multiline: true,
                      rows: 4,
                    }}
                    disabled={disabled}
                  />
                  <ControllerInputText
                    control={control}
                    name={`resources.[0].configurations.${language.value}.cta`}
                    label={t('CTA de activación')}
                    size='full'
                    disabled={disabled}
                  />
                  <ControllerInputText
                    control={control}
                    name={`resources.[0].configurations.${language.value}.info_page_url`}
                    label={t('Enlace informativo')}
                    size='full'
                    disabled={disabled}
                  />
                  <ControllerInputText
                    control={control}
                    name={`resources.[0].configurations.${language.value}.link_name`}
                    label={t('Nombre del enlace')}
                    size='full'
                    disabled={disabled}
                  />
                  <Typography variant='h6' sx={{ mt: 2 }}>
                    {t('Imágenes')}
                  </Typography>
                  {IMAGES_SIZES.map((image, i) => (
                    <ControllerInputImage
                      mandatory
                      key={i}
                      control={control}
                      name={`resources.[0].configurations.${language.value}.${image.value}`}
                      label={image.label}
                      size='full'
                      disabled={disabled}
                    />
                  ))}
                </CustomTabPanel>
              ))}
            </GridItem>
          </GridContainer>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <GridContainer container>
            <ControllerInputCSV control={control} name='csv' label={t('Fichero CSV')} size='full' />
            <GridItem md={12} item xs={12} sx={{ mt: 2 }}>
              <CampaignDiscounts />
            </GridItem>
          </GridContainer>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <GridContainer container>
            <GridItem md={12} item xs={12}>
              <CampaignHistoric />
            </GridItem>
          </GridContainer>
        </CustomTabPanel>
        <FormButtonsContainerSticky>
          <BasicButton text={t('Volver')} variant='text' size='medium' type='reset' handleClick={handleBack} />
          {!disabled && (
            <>
              <BasicButton
                text={t('Eliminar')}
                variant='text'
                size='medium'
                type='button'
                handleClick={() => setShowDeleteModal(true)}
              />
              <BasicButton
                text={t('Guardar')}
                variant='text'
                size='medium'
                type='submit'
                disabled={!isDirty}
                handleClick={() => setAction('save_draft')}
              />
              <BasicButton
                text={t('Publicar')}
                variant='contained'
                size='medium'
                type='submit'
                disabled={isDirty}
                handleClick={() => setAction('publish')}
              />
            </>
          )}
        </FormButtonsContainerSticky>
      </Grid>
      <ModalConfirm
        open={showBackModal}
        onCancel={() => setShowBackModal(false)}
        onConfirm={() => navigate(routes.campaignsList)}
        title={t('Descartar cambios')}
        message={t(
          // eslint-disable-next-line max-len
          'Se perderán los cambios realizados. ¿Desea continuar?'
        )}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
      <ModalConfirm
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={() => handleDelete()}
        title={t('Eliminar borrador')}
        message={t(
          // eslint-disable-next-line max-len
          'Se eliminará el borrador. ¿Desea continuar?'
        )}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
      <ModalConfirm
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => publishCampaign()}
        title={t('Publicar campaña')}
        message={t(
          // eslint-disable-next-line max-len
          'Está a punto de publicar esta campaña. Si la publica se activará. ¿Desea continuar?'
        )}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
    </>
  )
}

export default DraftsEditForm
