import React from 'react'

import Box from '@mui/material/Box'

import { StyledTab } from './styled'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
  return (
    <StyledTab
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box style={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
    </StyledTab>
  )
}

export default CustomTabPanel
