import PageContainer from 'components/atoms/page-container'
import CampaignsCreateForm from 'components/organisms/campaigns/campaigns-create-form'

const CampaignsCreate = (): JSX.Element => (
  <PageContainer>
    <CampaignsCreateForm />
  </PageContainer>
)

export default CampaignsCreate
