import { useQuery } from 'react-query'

import { GET_CAMPAIGNS } from 'config/queries'
import { CampaignsResponse, UseGetCampaigns } from 'models/campaigns'
import { getCampaigns } from 'services/campaigns'
import { CampaignFilterFormFileds } from 'validations/campaigns'

interface Args {
  limit: number
  offset: number
  filters: CampaignFilterFormFileds
  sort: string
}

const useGetCampaigns = ({ limit, offset, filters, sort }: Args): UseGetCampaigns => {
  const queryKey = [GET_CAMPAIGNS, limit, offset, filters, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getCampaigns({ limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<CampaignsResponse> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetCampaigns
