import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import BasicButton from 'components/atoms/basic-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import { CampaignFilterFormFileds, campaignFilterSchema, campaignsFormDefaultValues } from 'validations/campaigns'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: CampaignFilterFormFileds) => void
  active?: boolean
}

const CampaignsFilter: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CampaignFilterFormFileds>({
    defaultValues: campaignsFormDefaultValues,
    resolver: yupResolver(campaignFilterSchema),
  })

  // const { response: domainsResponse } = useGetDomains()

  const handleReset = useCallback(() => {
    reset({ ...campaignsFormDefaultValues })
    onSearch({ ...campaignsFormDefaultValues })
  }, [onSearch, reset])

  const onSubmit = (payload: CampaignFilterFormFileds) => {
    try {
      onSearch({ ...payload })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
  /* const langs = [
    { value: 'es', text: 'ES' },
    { value: 'pt', text: 'PT' },
  ] */
  const campaignTypes = [
    { value: 'VOUCHER', text: 'VOUCHER' },
    { value: 'INFO', text: 'INFO' },
  ]
  const activationMode = [
    { value: 'MANUAL', text: 'MANUAL' },
    { value: 'AUTOMATIC', text: 'AUTOMÁTICO' },
  ]

  // Pendiente definir el filtro 'ESTADO'
  /* const domains = useMemo(
    () => domainsResponse?.map?.(({ name: domain }: Domains) => ({ value: domain, text: t(domain) })),
    [domainsResponse, t]
  ) */

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={6} pr={1}>
        <ControllerInputText control={control} name='name' error={errors.name} label={t('Nombre')} size='full' />
      </Grid>
      {/* <Grid item md={3} pr={1}>
        <ControllerInputSelect
          options={langs}
          control={control}
          name='lang'
          error={errors.lang}
          label={t('Idioma')}
          size='full'
        />
      </Grid> */}
      <Grid item md={3} pr={1}>
        <ControllerInputSelect
          options={campaignTypes}
          control={control}
          name='type'
          error={errors.type}
          label={t('Tipo')}
          size='full'
        />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputSelect
          options={activationMode}
          control={control}
          name='activationMode'
          error={errors.activationMode}
          label={t('Activación')}
          size='full'
        />
      </Grid>
      {/* <Grid item md={3} pr={1}>
        <ControllerInputSelect
          options={domains}
          control={control}
          name='estado'
          error={errors.lang}
          label={t('Estado')}
          size='full'
        />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='from'
          error={errors.from}
          label={t('Vigencia desde')}
          size='full'
          pattern={DateFormats.iso}
        />
      </Grid>
      <Grid item md={3} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='to'
          error={errors.to}
          label={t('Vigencia hasta')}
          size='full'
          pattern={DateFormats.iso}
        />
      </Grid> */}

      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        {/* <ControllerSwitch label={t('Solo activos')} name='active' variant='full' control={control} autoHeight /> */}
        <BasicButton handleClick={handleReset} text={t('Restablecer')} size='small' color='warning' type='button' />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default CampaignsFilter
