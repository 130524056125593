import { useMutation } from 'react-query'

import { UploadCsv } from 'models/campaigns'
import { uploadCampaignCSV } from 'services/campaigns'

const UseUploadCampaignCsv = () => {
  const uploadCsv = useMutation({
    mutationFn: ({ id, ...payload }: UploadCsv) => uploadCampaignCSV(id, payload.file),
  })

  return uploadCsv
}

export default UseUploadCampaignCsv
