import { useQuery } from 'react-query'

import { GET_CAMPAIGN_DISCOUNTS } from 'config/queries'
import { getCampaignDiscounts } from 'services/campaigns'

interface Args {
  id: string
  limit: number
  offset: number
  sort: string
}

const useGetCampaignDiscounts = ({ id, limit, offset, sort }: Args) => {
  const queryKey = [GET_CAMPAIGN_DISCOUNTS, id, limit, offset, sort]

  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getCampaignDiscounts({ id, limit, offset, sort }),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result = response?.data

  return { response: result, isLoading }
}

export default useGetCampaignDiscounts
