import * as React from 'react'

import { InputProps } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'

import { Sizes } from 'models/sizes'
import { inputsWidth } from 'config/constants'
import { ControllerField } from 'models/form'

import { StyledBox } from './styled'

interface Props {
  field?: ControllerField
  label: string
  mandatory?: boolean
  defaultRequired?: boolean
  helperText?: string
  error?: boolean
  size: Sizes
  disabled?: boolean
  inputProps?: InputProps
}

const BasicInputText: React.FC<Props> = ({
  field,
  label,
  mandatory,
  defaultRequired,
  helperText,
  error,
  size,
  disabled,
  inputProps,
}) => {
  const { multiline, rows } = inputProps || {}
  return (
    <StyledBox width={inputsWidth[size]} multiline={multiline} rows={rows as number}>
      <TextField
        {...field}
        required={defaultRequired}
        label={mandatory ? `${label} *` : label}
        helperText={helperText}
        error={error}
        InputProps={inputProps}
        disabled={disabled}
        fullWidth
      />
    </StyledBox>
  )
}

export default BasicInputText
