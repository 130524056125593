import {
  CreateCampaign,
  CreateDraft,
  GetCampaignDiscounts,
  GetCampaigns,
  GetCampaignsVersions,
  GetOneCampaignVersion,
  UpdateCampaign,
  UpdateDraft,
} from 'models/campaigns'
import axios from 'services'
import { removeEmpty } from 'utils/removeEmpty'

export const getCampaigns = ({ filters, ...payload }: GetCampaigns) => {
  return axios.get('/v1/campaigns', {
    params: removeEmpty({ ...payload, ...filters }),
  })
}

export const getOneCampaign = (id?: string) => {
  return axios.get(`/v1/campaigns/${id}`)
}

export const getOneCampaignDraft = (id?: string) => {
  return axios.get(`/v1/drafts/${id}`)
}

export const getCampaignsVersions = ({ id, ...params }: GetCampaignsVersions) => {
  return axios.get(`/v1/campaigns/${id}/versions`, { params })
}

export const getOneCampaignsVersions = ({ id, idManaged, ...params }: GetOneCampaignVersion) => {
  return axios.get(`/v1/campaigns/${id}/versions/${idManaged}`, { params })
}

export const createCampaign = (payload: CreateCampaign) => {
  return axios.post('/v1/campaigns', payload)
}

export const cloneCampaign = (id: string) => {
  return axios.post(`/v1/drafts/${id}`)
}

export const updateOneCampaign = (id: string | undefined, payload: UpdateCampaign) => {
  return axios.patch(`/v1/campaigns/${id}`, payload)
}

export const createDraft = (payload: CreateDraft) => {
  return axios.post('/v1/drafts', payload)
}

export const updateOneDraft = (id: string | undefined, payload: UpdateDraft) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id: _, ...updatedPayload } = payload
  return axios.patch(`/v1/drafts/${id}`, updatedPayload)
}

export const deleteOneDraft = (id: string | undefined) => {
  return axios.delete(`/v1/drafts/${id}`)
}

export const publishDraft = (id: string | undefined) => {
  return axios.post(`/v1/drafts/${id}/publish`)
}

export const uploadCampaignCSV = (id: string, file: Blob) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`/v1/drafts/${id}/discounts/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getCampaignDiscounts = ({ id, ...params }: GetCampaignDiscounts) => {
  return axios.get(`/v1/drafts/${id}/discounts`, { params })
}
