import React from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import { Campaign } from 'models/campaigns'
import { RowHeight, TableColumnWidth } from 'config/constants'
import { dateFormatter } from 'utils/dateFormatter'

interface Props {
  id?: string
  rows: Campaign[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
}

const CampaignsHistoricTable: React.FC<Props> = ({ id, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToShow = (idManaged: string) => {
    const route = routes.campaignsHistoricShow.replace(':id', id ?? '').replace(':idManaged', idManaged)
    navigate(route)
  }

  const columns: GridEnrichedColDef[] = [
    {
      field: '•',
      headerName: `${t('')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: `${t('Versiones')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Campaign }) => <Typography>{row.name}</Typography>,
    },
    {
      field: 'updateDate',
      headerName: `${t('Fecha de modificación')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Campaign }) => <Typography>{dateFormatter({ date: row.updateDate })}</Typography>,
    },
    {
      field: 'username',
      headerName: `${t('Modificado por')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Campaign }) => <Typography>{row.username}</Typography>,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.xs,
      getActions: ({ row }: { row: Campaign }) => [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.idManaged)} />,
      ],
    },
  ]

  return (
    <>
      <BasicTable
        {...props}
        rowHeight={RowHeight.small}
        columns={columns}
        // ToDo: get one campaign version with id_managed and timestamp
        onRowClick={({ row }: { row: Campaign }) => {
          navigateToShow(row.idManaged)
        }}
      />
    </>
  )
}

export default CampaignsHistoricTable
