import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'

import usePagination from 'hooks/usePagination'
import useSort from 'hooks/useSort'
import CampaignsHistoricTable from 'components/organisms/campaigns/campaigns-historic-table'
import useGetCampaignsVersions from 'hooks/queries/campaign/useGetCampaignsVersions'

const CampaignHistoric = (): JSX.Element => {
  const { id } = useParams()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, setSortModel } = useSort()

  const { response, isLoading, count } = useGetCampaignsVersions({
    id,
    offset,
    limit: pageSize,
    sort: sortModel || 'updateDate',
  })

  return (
    <Box sx={{ width: '100%' }}>
      <CampaignsHistoricTable
        id={id}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
    </Box>
  )
}

export default CampaignHistoric
