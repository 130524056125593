import { useQuery } from 'react-query'

import { GET_ONE_CAMPAIGN } from 'config/queries'
import { Campaign, UseGetOneCampaign } from 'models/campaigns'
import { getOneCampaign } from 'services/campaigns'

interface Args {
  id: string
}

const useGetOneCampaign = ({ id }: Args): UseGetOneCampaign => {
  const queryKey = [GET_ONE_CAMPAIGN, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneCampaign(id),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Campaign = response?.data

  return { response: result, isLoading }
}

export default useGetOneCampaign
