import React from 'react'

import { GridSortModel, GridEnrichedColDef, GridRowParams, GridSelectionModel } from '@mui/x-data-grid'

import { ROWS_PER_PAGE_OPTIONS, Sort, TableTypes } from 'config/constants'
import NoRows from 'components/molecules/no-rows'
import { Resource } from 'models/resources'
import CustomPagination from 'components/atoms/custom-pagination'

import { StyledDataGrid } from './styled'

interface Props {
  type?: TableTypes
  rows: Array<Resource>
  columns: GridEnrichedColDef[]
  pageSize: number
  page: number
  isLoading: boolean
  count: number
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
  onRowClick: (row: GridRowParams) => void
  onSortModelChange: (sortString: string) => void
  rowHeight?: number
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  selectionModel?: GridSelectionModel
  rowsPerPageOptionsShow?: boolean
}

const BasicTable: React.FC<Props> = ({
  type,
  rows,
  columns,
  page,
  pageSize,
  count,
  isLoading,
  onPageSizeChange,
  onPageChange,
  onSortModelChange,
  onRowClick,
  rowHeight = undefined,
  selectionModel,
  onSelectionModelChange,
  rowsPerPageOptionsShow = true,
}) => {
  const handleSortModelChange = (sortModel: GridSortModel) => {
    const [sortItem]: GridSortModel = sortModel

    if (sortItem.sort === Sort.asc) return onSortModelChange(sortItem.field)
    if (sortItem.sort === Sort.desc) return onSortModelChange(`-${sortItem.field}`)

    return onSortModelChange('')
  }

  const isDisableSelection = ![TableTypes.selection].includes(type || TableTypes.default)
  const isCheckBoxSelection = [TableTypes.selection].includes(type || TableTypes.default)

  return (
    <StyledDataGrid
      componentsProps={{ header: { 'data-testid': 'basic-table' } }}
      autoHeight
      disableColumnMenu
      disableColumnFilter
      disableSelectionOnClick={isDisableSelection}
      checkboxSelection={isCheckBoxSelection}
      keepNonExistentRowsSelected
      paginationMode='server'
      sortingMode='server'
      rowHeight={rowHeight}
      rows={rows}
      columns={columns}
      page={page}
      loading={isLoading}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptionsShow ? ROWS_PER_PAGE_OPTIONS : []}
      rowCount={count}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      onRowClick={row => onRowClick(row)}
      onSortModelChange={handleSortModelChange}
      sortingOrder={[Sort.desc, Sort.asc]}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selectionModel}
      components={{
        NoRowsOverlay: NoRows,
        Pagination: CustomPagination,
      }}
    />
  )
}

export default BasicTable
