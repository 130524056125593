import React from 'react'

import Container from './styled'

interface Props {
  children?: React.ReactNode | Array<React.ReactNode>
}

const FormButtonsContainerSticky: React.FC<Props> = ({ children }) => (
  <Container item xs={12}>
    {children}
  </Container>
)

export default FormButtonsContainerSticky
