import axios from 'services'

export const uploadFile = (file: Blob) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post('/v1/resource_file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getFile = (id: string) => {
  return axios.get(`/v1/resource_file/${id}`)
}
