import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { CreateDraft } from 'models/campaigns'
import { createDraft } from 'services/campaigns'
import { GET_CAMPAIGNS, GET_GROUPS } from 'config/queries'

const useCreateDraft = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createDraftMutation = useMutation({
    mutationFn: (payload: CreateDraft) => createDraft(payload),
    onSuccess: () => {
      toast(t('Borrador creado'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
    },
  })

  return createDraftMutation
}

export default useCreateDraft
