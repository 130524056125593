import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { UpdateCampaign, UpdateDraft } from 'models/campaigns'
import { updateOneDraft } from 'services/campaigns'
import { GET_CAMPAIGNS, GET_GROUPS, GET_ONE_CAMPAIGN_DRAFT } from 'config/queries'

const useUpdateDraft = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const updateCampaign = useMutation({
    mutationFn: ({ id, ...payload }: UpdateDraft) => updateOneDraft(id, { id, ...payload }),
    onSuccess: () => {
      toast(t('Borrador actualizado'), { type: 'success' })
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [GET_CAMPAIGNS] })
      const previous = queryClient.getQueryData<{ data: UpdateCampaign[] }>([GET_CAMPAIGNS])
      queryClient.invalidateQueries({ queryKey: [GET_ONE_CAMPAIGN_DRAFT] })
      return { previous }
    },
    onError: (error, newValue, context) => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.setQueryData([GET_CAMPAIGNS], context?.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CAMPAIGNS] })
      queryClient.invalidateQueries({ queryKey: [GET_GROUPS] })
    },
  })

  return updateCampaign
}

export default useUpdateDraft
