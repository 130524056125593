const routes = {
  root: '/',
  login: '/login',
  cognitoRedirect: '/cognito_redirect',

  identitiesList: '/identities',
  identitiesCreate: '/identities/new',
  identitiesEdit: '/identities/:id/edit',
  identitiesShow: '/identities/:id',
  identitiesHistoric: '/identities/:id/versions',
  identitiesHistoricShow: '/identities/:id/versions/:version',

  groupsList: '/groups',
  groupsCreate: '/groups/new',
  groupsEdit: '/groups/:id/edit',
  groupsShow: '/groups/:id',
  groupsHistoric: '/groups/:id/versions',
  groupsHistoricShow: '/groups/:id/versions/:version',

  relationsList: '/relations',
  relationsCreate: '/relations/new',
  relationsEdit: '/relations/:id/edit',
  relationsShow: '/relations/:id',
  relationsHistoric: '/relations/:id/versions',
  relationsHistoricShow: '/relations/:id/versions/:version',

  administration: '/administration',

  usersList: '/administration/users',
  usersHistoric: '/administration/users/historic',
  usersCreate: '/administration/users/new',
  usersEdit: '/administration/users/:id/edit',
  usersShow: '/administration/users/:id',

  applicationsList: '/administration/applications',
  applicationsHistoric: '/administration/applications/historic',
  applicationsCreate: '/administration/applications/new',
  applicationsEdit: '/administration/applications/:id/edit',
  applicationsShow: '/administration/applications/:id',

  eventsList: '/administration/audits',
  eventsShow: '/administration/audits/:id',

  locationsList: '/administration/locations',
  locationsHistoric: '/administration/locations/historic',
  locationsCreate: '/administration/locations/new',
  locationsEdit: '/administration/locations/:id/edit',
  locationsShow: '/administration/locations/:id',

  campaignsList: '/campaigns',
  campaignsCreate: '/campaigns/new',
  campaignsEdit: '/campaigns/:id/edit',
  campaignsShow: '/campaigns/:id',
  campaignsHistoric: '/campaigns/:id/versions',
  campaignsHistoricShow: '/campaigns/:id/versions/:idManaged',

  campaignDraftShow: '/campaigns/draft/:id',
  campaignDraftEdit: '/campaigns/draft/:id/edit',
}

export default routes
