import styled from '@emotion/styled'
import { Box } from '@mui/system'

import { INPUT_CONTAINER_HEIGHT, INPUT_BASE_HEIGHT } from 'config/constants'
import { colors } from 'config/theme'

interface Props {
  width: string
  disabled?: boolean
}

export const StyledBox = styled(Box)`
  width: ${(props: Props) => props.width};
  height: calc(${INPUT_CONTAINER_HEIGHT} * 1.75);
  display: flex;
  align-items: center;
  border: 1px dashed ${Props => (Props.disabled ? colors.darkGrey : colors.deepBlue)};
  margin-top: 3rem;
  border-radius: 0.375rem;
  position: relative;

  & .header {
    position: absolute;
    top: -1.7rem;
    left: 50%;
    color: ${Props => (Props.disabled ? colors.darkGrey : colors.deepBlue)};
    font-size: 1rem;
    font-weight: 400;
    transform: translateX(-50%);
  }

  & .MuiFormControl-root {
    width: 100%;
    height: 100%;
    z-index: 2;

    & .MuiInputBase-root {
      height: 100%;

      & fieldset {
        border-width: 0;
      }

      & input {
        opacity: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
  }

  .csv-loader-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .csv-loaded-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: ${INPUT_BASE_HEIGHT};
    border-radius: 0.375rem;
    background-color: ${colors.grey};
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .csv-loaded-path {
      font-size: 0.75rem;
      color: ${Props => (Props.disabled ? colors.darkGrey : colors.deepBlue)};
      font-weight: 300;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .csv-loaded-delete {
      cursor: pointer;
      color: ${colors.deepBlue};
    }
  }

  .csv-loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: ${Props => (Props.disabled ? colors.darkGrey : colors.deepBlue)};

    .csv-loader-action {
      font-size: 0.875rem;
      color: ${Props => (Props.disabled ? colors.darkGrey : colors.deepBlue)};
      font-weight: 300;
    }

    .csv-loader-type {
      font-size: 0.8125rem;
      color: ${colors.darkGrey};
    }
  }

  .csv-loaded-info {
    font-size: 0.875rem;
    color: ${colors.darkGrey};
    font-weight: 300;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 1rem;
  }
`
