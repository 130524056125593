import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import useGetOneCampaignDraft from 'hooks/queries/draft/useGetOneDraft'
import DraftsEditForm from 'components/organisms/campaigns/drafts-edit-form'

const CampaignsDraftEditPage = (): JSX.Element => {
  const { id } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isLoading, response } = useGetOneCampaignDraft({ id: id! })
  return (
    <PageContainer>
      <DraftsEditForm isLoading={isLoading} response={response} campaignId={id} />
    </PageContainer>
  )
}

export default CampaignsDraftEditPage
