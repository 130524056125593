import { useQuery } from 'react-query'

import { GET_CAMPAIGNS_VERSIONS } from 'config/queries'
import { getCampaignsVersions } from 'services/campaigns'
import { Campaign, UseGetCampaignsVersions } from 'models/campaigns'

interface Args {
  id?: string
  limit: number
  offset: number
  sort: string
}

const useGetCampaignsVersions = ({ id, limit, offset, sort }: Args): UseGetCampaignsVersions => {
  const queryKey = [GET_CAMPAIGNS_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getCampaignsVersions({ id, limit, offset, sort }),
    refetchOnMount: true,
  })

  const result: Array<Campaign> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetCampaignsVersions
