import { InputProps, TextField, Typography } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import { t } from 'i18next'
import DeleteIcon from '@mui/icons-material/Delete'

import { ControllerField } from 'models/form'
import { Sizes } from 'models/sizes'
import { inputsWidth } from 'config/constants'

import { StyledBox } from './styled'

interface Props {
  field?: ControllerField
  label: string
  mandatory?: boolean
  defaultRequired?: boolean
  helperText?: string
  error?: boolean
  size: Sizes
  disabled?: boolean
  inputProps?: InputProps
}

const BasicInputImage: React.FC<Props> = ({
  field,
  label,
  mandatory,
  defaultRequired,
  helperText,
  error,
  size,
  disabled,
  inputProps,
}: Props) => {
  return (
    <StyledBox width={inputsWidth[size]} disabled={disabled}>
      <Typography className='header'>{mandatory ? `${label} *` : label}</Typography>
      {!(field?.value as string) && !disabled && (
        <TextField
          {...field}
          required={defaultRequired}
          helperText={helperText}
          error={error}
          InputProps={inputProps}
          disabled={disabled}
          fullWidth
          type='file'
          inputProps={{ accept: 'image/jpeg, image/png' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => field?.onChange(e.target.files?.[0])}
        />
      )}

      {!(field?.value as string) && (
        <div className='image-loader-container'>
          <ImageIcon />
          <Typography
            className='image-loader-action'
            dangerouslySetInnerHTML={{ __html: t('Clica para adjuntar o arrastra hasta esta zona') }}
          />
          <Typography className='image-loader-type'>{t('JPG o PNG')}</Typography>
        </div>
      )}
      {typeof field?.value === 'string' && (field?.value as string)?.startsWith('http') && (
        <div className='image-preview-container'>
          <img src={field?.value as string} alt={label} />
        </div>
      )}
      {(field?.value as string) && !disabled && (
        <div className='image-loaded-container'>
          <Typography className='image-loaded-path'>
            <ImageIcon /> {typeof field?.value === 'string' ? field?.value : (field?.value as File).name}
          </Typography>
          {!disabled && (
            <a className='image-loaded-delete' onClick={() => field?.onChange(undefined)}>
              <DeleteIcon />
            </a>
          )}
        </div>
      )}
    </StyledBox>
  )
}

export default BasicInputImage
