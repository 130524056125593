import React, { useCallback } from 'react'

import { GridActionsCellItem } from '@mui/x-data-grid'
import DraftIcon from '@mui/icons-material/SaveAsSharp'
import CheckIcon from '@mui/icons-material/CheckCircle'
import { Box, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import routes from 'config/routes'

interface Props {
  hasDrafts: boolean
  isPublished: boolean
  publishedID?: string
  draftID?: string
}

const ShowGridDraftsInfoCell: React.FC<Props> = ({ hasDrafts, isPublished, publishedID, draftID }) => {
  const navigate = useNavigate()
  const navigateToPublished = useCallback((id: string) => navigate(routes.campaignsShow.replace(':id', id)), [navigate])
  const navigateToDraft = useCallback((id: string) => navigate(routes.campaignDraftShow.replace(':id', id)), [navigate])

  return (
    <Box width='100%' display='flex'>
      {isPublished && (
        <Tooltip title={t('Publicada')}>
          <GridActionsCellItem
            icon={<CheckIcon color='primary' />}
            label='show'
            onClick={() => {
              navigateToPublished(publishedID ?? '')
            }}
          />
        </Tooltip>
      )}
      {hasDrafts === true && (
        <Tooltip title={t('Borrador')}>
          <GridActionsCellItem
            icon={<DraftIcon color='primary' />}
            label='show'
            onClick={() => {
              navigateToDraft(draftID ?? '')
            }}
          />
        </Tooltip>
      )}
    </Box>
  )
}
export default ShowGridDraftsInfoCell
