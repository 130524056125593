import React from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import { ListProps } from 'models/list'
import usePagination from 'hooks/usePagination'
import useGroupsPermissions from 'hooks/permissions/useGroupsPermissions'
import useFilters from 'hooks/useFilters'
import CampaignsTable from 'components/organisms/campaigns/campaigns-table'
import useGetCampaigns from 'hooks/queries/campaign/useGetCampaigns'
import CampaignsFilter from 'components/organisms/campaigns/campaigns-filter'
import { campaignsFormDefaultValues } from 'validations/campaigns'

const CampaignsList: React.FC<ListProps> = ({ type, ...props }) => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useGroupsPermissions()
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onToggleFilter, onSearchFilter } =
    useFilters(campaignsFormDefaultValues)

  const { response, isLoading, count, queryKey } = useGetCampaigns({
    offset,
    limit: pageSize,
    filters: { ...filters },
    sort: sortModel || 'id',
  })

  return (
    <Box width='100%'>
      <ResourceHeader>
        {/* <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} /> */}
        <ResourceFilter
          badge={badge}
          label={t`Identificador, nombre, idioma, tipo ...`}
          openFilter={openFilter}
          onSearchQ={onSearchQ}
          onToggleFilter={onToggleFilter}
        />

        {!type && canWrite && <NewResourceButton label={t('Nueva campaña')} to={routes.campaignsCreate} />}
      </ResourceHeader>
      {openFilter && <CampaignsFilter onSearch={onSearchFilter} />}
      <CampaignsTable
        {...props}
        rows={response?.length > 0 ? [...(response[0].campaigns || []), ...(response[0].drafts || [])] : []}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
        type={type}
      />
    </Box>
  )
}

export default CampaignsList
