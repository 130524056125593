import styled from '@emotion/styled'
import { Box } from '@mui/system'

import { INPUT_BASE_HEIGHT, INPUT_CONTAINER_HEIGHT } from 'config/constants'

interface Props {
  width: string
  multiline?: boolean
  rows?: number
}

export const StyledBox = styled(Box)`
  width: ${(props: Props) => props.width};
  height: ${props =>
    props.multiline ? `calc(${INPUT_BASE_HEIGHT}  + 1.7rem * ${props.rows || 1})` : INPUT_CONTAINER_HEIGHT};
  display: flex;
  align-items: flex-start;

  & .MuiFormControl-root {
    width: 100%;
    height: ${INPUT_BASE_HEIGHT};
  }
`
