/* eslint-disable camelcase */
import { useCallback, useState } from 'react'

import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useNavigate } from 'react-router-dom'

import CustomTabPanel from 'components/atoms/custom-tab-panel'
import { CampaignFormSchema, campaignSchema } from 'validations/campaigns'
import ControllerInputText from 'components/molecules/controller-input-text'
import useGetGroups from 'hooks/queries/group/useGetGroups'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerSwitch from 'components/molecules/controller-switch'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import { CampaignActivationModeEnum, IMAGES_SIZES, TABS_LANGUAGES } from 'config/constants'
import ControllerInputImage from 'components/molecules/controller-input-image'
import FormButtonsContainerSticky from 'components/atoms/form-button-container-sticky'
import CancelButton from 'components/atoms/cancel-button'
import BasicButton from 'components/atoms/basic-button'
import ModalConfirm from 'components/molecules/modal-confirm'
import ControllerInputCSV from 'components/molecules/controller-input-csv'
import useUploadFile from 'hooks/queries/file/useUploadFile'
import useCreateCampaign from 'hooks/queries/campaign/useCreateCampaign'
import { CreateCampaign, CreateDraft } from 'models/campaigns'
import useCreateDraft from 'hooks/queries/draft/useCreateDraft'
import { convertToPng } from 'utils/convertToImagePng'
import { getFile } from 'services/file'
import useUploadCampaignCsv from 'hooks/queries/campaign/useUploadCampaignCsv'
import routes from 'config/routes'

import { GridContainer, GridItem, StyledContainer } from './styled'

const CampaignsCreateForm = (): JSX.Element => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)
  const [tabLanguage, setTabLanguage] = useState(0)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const { mutate: uploadFile } = useUploadFile()
  const createCampaign = useCreateCampaign()
  const createDraft = useCreateDraft()
  const navigate = useNavigate()
  const uploadCSV = useUploadCampaignCsv()

  const { response: groupsResponse } = useGetGroups({
    offset: 0,
    limit: 1000,
    filters: { showActiveOnly: true, keyType: 'GROUP' },
    sort: 'id',
  })

  const groups = [...groupsResponse.map(group => ({ value: group.name, text: group.name }))]

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleLanguageChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabLanguage(newValue)
  }

  const defaultValues: CampaignFormSchema = {
    id: '',
    idManaged: '',
    priority: 0,
    countries: [],
    name: '',
    domain: [],
    // active: false,
    startTimestamp: '',
    endTimestamp: '',
    type: 'INFO',
    activationMode: false,
    createDate: '',
    updateDate: '',
    csv: null,
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CampaignFormSchema>({
    defaultValues,
    resolver: yupResolver(campaignSchema),
  })

  const payload = watch()

  const onSubmit = useCallback(
    async (data: CampaignFormSchema) => {
      const draftPayload: CreateDraft = {
        name: data.name,
        idManaged: data.idManaged, // TODO:
        priority: data.priority,
        type: data.type,
        activationMode: data.activationMode ? CampaignActivationModeEnum.AUTOMATIC : CampaignActivationModeEnum.MANUAL,
        default_language: 'es',
        from: data.startTimestamp,
        to: data.endTimestamp,
        resources: data.countries,
        conditions: data.domain ? [{ kind_of: 'GROUP_KEY', value: data.domain }] : undefined,
      }

      if (data.countries) {
        for (const language of TABS_LANGUAGES) {
          const langCode = language.value

          if (data?.countries?.[0].configurations[langCode]) {
            for (const imageSize of IMAGES_SIZES) {
              const sizeCode = imageSize.value
              const resource = data?.countries?.[0].configurations[langCode]?.[sizeCode]

              if (resource !== null && resource !== undefined && typeof resource !== 'string') {
                try {
                  const image = await convertToPng(resource)

                  await new Promise<void>((resolve, reject) => {
                    uploadFile(image, {
                      onSuccess: async result => {
                        try {
                          const file = await getFile(result.data.id)
                          draftPayload.resources[0].configurations[langCode][sizeCode] = file.data.url
                          resolve()
                        } catch (error) {
                          reject(error)
                        }
                      },
                      onError: error => reject(error),
                    })
                  })
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error)
                }
              }
            }
          }
        }
      }
      /** IMAGES END */

      await createDraft.mutateAsync(draftPayload, {
        onSuccess: draft => {
          if (data.type === 'VOUCHER' && data.csv) {
            uploadCSV.mutate(
              { id: draft.data.id, file: data.csv },
              {
                onSuccess: async response => {
                  // eslint-disable-next-line no-console
                  console.log(response)
                },
              }
            )
          }
          navigate(routes.campaignDraftEdit.replace(':id', draft.data.id.toString()))
        },
      })
    },
    [createDraft, navigate, uploadCSV, uploadFile]
  )

  const publishCampaign = useCallback(async () => {
    if (payload) {
      const campaignPayload: CreateCampaign = {
        name: payload.name,
        idManaged: payload.name, // TODO:
        priority: payload.priority,
        type: payload.type,
        activationMode: payload.activationMode ? 'AUTOMATIC' : 'MANUAL',
        default_language: 'es',
        start_timestamp: payload.startTimestamp,
        end_timestamp: payload.endTimestamp,
        countries: [],
      }
      if (payload.countries && payload.countries.length > 0) {
        TABS_LANGUAGES.forEach(language => {
          const langCode = language.value

          if (payload?.countries?.[0][langCode]) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            IMAGES_SIZES.forEach(imageSize => {
              // const sizeCode = imageSize.value
              // TODO: Save campaign
            })
          }
        })
      }
      await createCampaign.mutateAsync(campaignPayload)
    }
  }, [createCampaign, payload])

  const selectedType = watch('type')

  return (
    <>
      <Grid component='form' onSubmit={handleSubmit(onSubmit)} data-testid='campaigns-create-form'>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem' }}>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label='campaigns form tabs'>
            <Tab label={t('Información básica')} />
            <Tab label={t('Descuentos y parkings')} disabled={selectedType === 'INFO'} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <GridContainer container>
            <GridContainer gap={1}>
              <GridItem item md={2} xs={5}>
                <ControllerInputText
                  mandatory
                  control={control}
                  name='priority'
                  error={errors.priority}
                  label={t('Nº Prioridad')}
                  size='full'
                />
              </GridItem>
              <GridItem item md={9} xs={6}>
                <ControllerInputText
                  mandatory
                  control={control}
                  name='name'
                  error={errors.name}
                  label={t('Nombre campaña')}
                  size='full'
                />
              </GridItem>
              <GridItem item md={7} xs={12}>
                <ControllerInputText
                  mandatory
                  control={control}
                  name='idManaged'
                  error={errors.idManaged}
                  label={t('Id Managed')}
                  size='full'
                />
              </GridItem>
              <GridItem item md={4} xs={12}>
                <Controller
                  name='type'
                  control={control}
                  render={({ field }) => (
                    <RadioGroup row {...field} aria-labelledby='radio-buttons-type' name='type'>
                      <FormControlLabel value='INFO' control={<Radio />} label={t('Información')} />
                      <FormControlLabel value='VOUCHER' control={<Radio />} label={t('Descuento')} />
                    </RadioGroup>
                  )}
                />
              </GridItem>
              <GridItem item md={7} xs={12}>
                <ControllerInputSelect
                  mandatory
                  control={control}
                  name='domain'
                  error={errors.domain ? (errors.domain as FieldError) : undefined}
                  label={t('Grupos')}
                  size='full'
                  options={groups}
                  multiple
                />
              </GridItem>
              <GridItem item md={4} xs={12}>
                <ControllerSwitch
                  label={t('Activación automática')}
                  name='activationMode'
                  variant='full'
                  control={control}
                  autoHeight
                />
              </GridItem>
              <GridItem md={11} item xs={12}>
                <StyledContainer direction='row'>
                  <ControllerInputDateTimePicker
                    mandatory
                    label={t('Desde')}
                    name='startTimestamp'
                    control={control}
                    error={errors.startTimestamp}
                    size='full'
                  />
                  <ControllerInputDateTimePicker
                    mandatory
                    label={t('Hasta')}
                    name='endTimestamp'
                    control={control}
                    error={errors.endTimestamp}
                    size='full'
                  />
                </StyledContainer>
              </GridItem>
            </GridContainer>
            <GridItem md={3} item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Tabs
                  value={tabLanguage}
                  onChange={handleLanguageChange}
                  orientation='vertical'
                  aria-label='campaigns languages tabs'
                >
                  {TABS_LANGUAGES.map((language, index) => (
                    <Tab key={index} label={t(language.label)} />
                  ))}
                </Tabs>
              </Box>
            </GridItem>
            <GridItem md={9} item xs={8}>
              {TABS_LANGUAGES.map((language, index) => (
                <CustomTabPanel key={language.value} value={tabLanguage} index={index}>
                  <ControllerInputText
                    control={control}
                    name={`countries.[0].configurations.${language.value}.title`}
                    label={t('Título')}
                    size='full'
                  />
                  <ControllerInputText
                    control={control}
                    name={`countries.[0].configurations.${language.value}.details`}
                    label={t('Descripción')}
                    size='full'
                    inputProps={{
                      multiline: true,
                      rows: 4,
                    }}
                  />
                  <ControllerInputText
                    control={control}
                    name={`countries.[0].configurations.${language.value}.cta`}
                    label={t('CTA de activación')}
                    size='full'
                  />
                  <ControllerInputText
                    control={control}
                    name={`countries.[0].configurations.${language.value}.info_page_url`}
                    label={t('Enlace informativo')}
                    size='full'
                  />
                  <ControllerInputText
                    control={control}
                    name={`countries.[0].configurations.${language.value}.link_name`}
                    label={t('Nombre del enlace')}
                    size='full'
                  />
                  <Typography variant='h6' sx={{ mt: 2 }}>
                    {t('Imágenes')}
                  </Typography>
                  {IMAGES_SIZES.map((image, i) => (
                    <ControllerInputImage
                      mandatory
                      key={i}
                      control={control}
                      name={`countries.[0].configurations.${language.value}.${image.value}`}
                      label={image.label}
                      size='full'
                    />
                  ))}
                </CustomTabPanel>
              ))}
            </GridItem>
          </GridContainer>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <GridContainer container>
            <ControllerInputCSV control={control} name='csv' label={t('Fichero CSV')} size='full' />
          </GridContainer>
        </CustomTabPanel>
        <FormButtonsContainerSticky>
          <CancelButton />
          <BasicButton text={t('Guardar borrador')} variant='text' size='medium' type='submit' />
        </FormButtonsContainerSticky>
      </Grid>
      <ModalConfirm
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => publishCampaign()}
        title={t('Publicar campaña')}
        message={t(
          // eslint-disable-next-line max-len
          'Está a punto de publicar esta campaña. Si la publica se activará. ¿Desea continuar?'
        )}
        confirmLabel={t('confirmar')}
        cancelLabel={t('cancelar')}
      />
    </>
  )
}

export default CampaignsCreateForm
