import React from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import BasicTable from 'components/molecules/basic-table'
import { RowHeight, TableColumnWidth } from 'config/constants'
import { Discount } from 'models/discounts'

interface Props {
  id?: string
  rows: Discount[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
}

const CampaignsDiscountsTable: React.FC<Props> = ({ id, ...props }) => {
  const { t } = useTranslation()

  const columns: GridEnrichedColDef[] = [
    {
      field: 'id',
      headerName: `${t('id')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'campaignCode',
      headerName: `${t('código campaña')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'carparkCode',
      headerName: `${t('código parking')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'discountType',
      headerName: `${t('tipo descuento')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'discount',
      headerName: `${t('descuento')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
    },
  ]

  return (
    <>
      <BasicTable
        {...props}
        rows={props.rows || []}
        rowHeight={RowHeight.small}
        columns={columns}
        // ToDo: get one campaign version with id_managed and timestamp
        onRowClick={() => {}}
      />
    </>
  )
}

export default CampaignsDiscountsTable
