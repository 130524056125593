import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'

import usePagination from 'hooks/usePagination'
import useSort from 'hooks/useSort'
import useGetCampaignDiscounts from 'hooks/queries/campaign/useGetCampaignDiscounts'
import CampaignsDiscountsTable from 'components/organisms/campaigns/campaigns-discounts-table'

const CampaignDiscounts = (): JSX.Element => {
  const { id } = useParams()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, setSortModel } = useSort()

  const { response, isLoading } = useGetCampaignDiscounts({
    id: id ?? '',
    offset,
    limit: pageSize,
    sort: sortModel || 'updateDate',
  })

  return (
    <Box sx={{ width: '100%' }}>
      <CampaignsDiscountsTable
        id={id}
        rows={response}
        count={1}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
    </Box>
  )
}

export default CampaignDiscounts
