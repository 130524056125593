import * as yup from 'yup'

import { ALPHANUMERIC_REGEX } from 'config/regex'

export const campaignFilterSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  lang: yup.string(),
  from: yup.string().nullable(),
  to: yup.string().nullable(),
  type: yup.string(),
  activationMode: yup.string(),
})

export type CampaignFilterFormSchema = yup.InferType<typeof campaignFilterSchema>

export interface CampaignFilterFormFileds {
  name?: string
  lang?: string
  from?: string | null
  to?: string | null
  type?: string
  activationMode?: string
  q?: string
}

export const campaignsFormDefaultValues: CampaignFilterFormFileds = {
  name: '',
  lang: '',
  type: '',
  activationMode: '',
  from: null,
  to: null,
}

export interface CampaignFormFields {
  id?: string
  priority: number
  name: string
  domain: Array<string>
  type: string
  activationMode: boolean
  startTimestamp: string
  endTimestamp: string
  createDate?: string
  updateDate?: string
  countries: Array<object>
  id_managed: string
}

export const campaignSchema = yup
  .object({
    id: yup.string(),
    priority: yup.number().required(),
    countries: yup.array(),
    name: yup.string().required().max(255).matches(ALPHANUMERIC_REGEX),
    domain: yup.array(yup.string().required()).min(1),
    // active: yup.boolean().required(),
    startTimestamp: yup.string().required(),
    endTimestamp: yup.string().required(),
    type: yup.string(),
    activationMode: yup.boolean(),
    createDate: yup.string(),
    updateDate: yup.string(),
    csv: yup.mixed(),
    idManaged: yup.string().required(),
  })
  .required()

export type CampaignFormSchema = yup.InferType<typeof campaignSchema>
